import React from "react"
import { Link, graphql } from "gatsby"
import Navigation from "../components/navigation"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Objetivo from "../images/SVG/objetivo.svg"
import Dream from "../images/iconos-08.png"
import Footer from "../components/footer"

const EjerciciosPage = props => (
  <Layout>
    <SEO title="Sobre Sueña en Colores" />
    <Navigation />

    <div className="w-full mx-auto top-illustration">
      <Img fluid={props.data.oneImage.childImageSharp.fluid} className="h-64" />
    </div>

    <div className="w-full mx-auto text-center bg-blue-200 pt-8 pb-8">
      <h1 className="text-3xl lg:text-4xl text-blue-900">
        Mindfulness para niños
      </h1>
      <div>
        <p className="w-10/12 lg:w-1/2 text-lg mt-3 mx-auto">
          No hay manera correcta o incorrecta de hacer estos ejercicios.
          ¡Hágalos de la mejor manera que pueda y diviértase haciéndolos!
        </p>
      </div>
    </div>

    <div className="w-full mx-auto bg-green-100 pt-8 pb-8">
      <div className="content-item w-full lg:w-10/12 lg:flex mx-auto">
        <div className="w-full lg:w-1/4 text-center">
          <img
            src={Objetivo}
            alt="Mindfulness y Arteterapa"
            className="mx-auto w-1/2 lg:w-8/12"
          />
        </div>
        <div className="w-full lg:w-3/4">
          <h2 className="text-2xl my-3  px-5 text-blue-800">
            Atención a la respiración
          </h2>
          <p className="px-5">
            Cuando ponemos atención a la respiración podemos aprender de
            nuestras sensaciones corporales y de nuestro estado emocional. Nos
            damos cuenta cómo se afecta la mente y el cuerpo.
          </p>

          <ol>
            <li>
              Siéntese en una posición cómoda, puede ser en una silla en el
              suelo o sobre un almohadón.
            </li>
            <li>
              Apoye su mano sobre alguna parte del cuerpo en donde sienta la
              respiración. Puede ser el estómago, el pecho o la nariz.
            </li>
            <li>
              Respire como lo hace normalmente. Lleve toda su atención a esa
              parte del cuerpo en donde siente la respiración.
            </li>
            <li>
              Cuando se distraiga, lleve la atención de vuelta a esa parte del
              cuerpo donde siente la respiración.
            </li>
          </ol>
          <p>
            ¿Puede estar consciente de su respiración durante cinco
            respiraciones? ¿Qué tal si lo hace durante 1 minuto?
          </p>

          <h2 className="text-2xl mb-3 mt-8 px-5 text-blue-800">
            Respirando como un globo
          </h2>
          <p className="px-5">
            La respiración prolongada tiene un efecto calmante y relajante. Al
            llevar la atención a la respiración, aprendemos a concentrarnos en
            una cosa a la vez.
          </p>

          <ol>
            <li>
              Ponga sus manos en el estómago y haga tres respiraciones
              profundas. Inhale y exhale.
            </li>
            <li>
              Imagínese qué cada vez que inhala está llenando el globo de aire.
            </li>
            <li>
              Imagínese qué cada vez que exhala está vaciando del globo de aire.
            </li>
            <li>Repítanlo de tres a cinco veces.</li>
          </ol>
          <p>¿Cómo se siente en este momento?</p>

          <h2 className="text-2xl mb-3 mt-8  px-5 text-blue-800">
            Bailando y calmando
          </h2>
          <p className="px-5">
            Cuando intercalamos movimientos suaves con movimientos rápidos para
            descargar el exceso de energía, ayudamos a aquietar el sistema
            nervioso central. Finalizamos con un descanso para aprender a
            aquietarnos después del movimiento.
          </p>

          <ol>
            <li>
              Vamos a imaginarnos que tenemos una goma mágica en las plantas de
              los pies y que estamos pegados al suelo. El adulto hace como si
              pusiera goma en la planta de cada uno de sus pies para que se
              adhieran al suelo. El adulto ejemplifica este y los siguientes
              pasos para que los niños lo puedan imitar.
            </li>
            <li>
              ¿Puede mover las rodillas de un lado a otro sin despegar los pies
              del suelo?
            </li>
            <li>
              Vamos a mover el cuerpo al sonido de un tambor, como si
              estuviéramos bailando pero manteniendo los pies pegados al suelo.
              (Puede utilizar las palmas de las manos u otro instrumento
              musical, realizando movimientos amplios cuando suba la intensidad
              del sonido.
            </li>
            <li>
              Haga movimientos pequeños cuando baje el volumen y la intensidad
              del sonido.{" "}
            </li>
            <li>
              ¿Qué va a hacer cuando el tambor suene rápido? (El adulto juega
              con la rapidez del sonido.){" "}
            </li>
            <li>
              ¿Y si suena lento? (El adulto toca lentamente el instrumento)
            </li>
            <li>
              Intente seguir estos movimientos y deténgase cuando el instrumento
              deje de sonar. (El adulto alterna entre tocar rápido, lento, alto
              o bajo y los niños se detendrán cuando pare el sonido)
            </li>
            <li>
              Ahora vamos a relajarnos sintiendo la respiración y moviendo
              lentamente las piernas.
            </li>
          </ol>
        </div>
      </div>
    </div>

    <div className="w-full mx-auto text-center bg-gray-100 pt-8 pb-8 border border-gray-200">
      <h1 className="text-2xl lg:text-4xl text-blue-900">
        Uno hace la diferencia
      </h1>
      <div className="mt-5">
        <p className="w-2/3 lg:w-1/2 text-md mt-3 mx-auto">
          La ciencia ha demostrado algo que todos intuimos. Ayudar a otros nos
          hace personas más felices. Ahora sigue el paso más importante: hacelo
          hoy, ayudá hoy.
        </p>
      </div>
    </div>

    <Footer />
  </Layout>
)

export default EjerciciosPage

export const anaQuery = graphql`
  query {
    oneImage: file(relativePath: { eq: "meditate.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
